import React from 'react'

import { IoSearch } from "react-icons/io5";
import format from "date-fns/format";

import { Collapse } from "components/Collapse";
import Button from "components/Safra/Button";
import SearchHotel from "components/Safra/SearchHotel";
import {Informations} from './styles'

import { useApp } from "contexts/AppContext";
import {isChannel} from "../../../utils";
import {useGenialApp} from "../../../contexts/GenialAppContext";

export default function SearchBarHotels({info, handleInfo, searchHotels}) {

    const { hotelState } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { locationSearchHotel, datesSearchHotel, guestsSearchHotel } = hotelState
    const { adults, children } = guestsSearchHotel
    const totalGuests = adults + children

    return (
        <div className="container-search-bar" style={{top: "inherit"}}>
            { info && (
                <>
                    <Informations>
                        <p>{datesSearchHotel.startDate ? format(datesSearchHotel.startDate, "dd/MM/yy") : '-'}<span>Check-in</span>
                        </p>
                        <p>{datesSearchHotel.endDate ? format(datesSearchHotel.endDate, "dd/MM/yy") : '-'}<span>Check-out</span>
                        </p>
                        <p>{totalGuests}<span>Hóspedes</span></p>
                    </Informations>
                    <div className="mb-3">
                    <Button width="200px" onClick={handleInfo} data-bs-toggle="collapse"
                                data-bs-target="#filter-collapse" aria-expanded="false" aria-controls="filter-collapse">
                            <IoSearch/> Refazer Busca
                        </Button>
                    </div>
                </>
            )}
            <Collapse handleClick={handleInfo}>
                <p className="fw-bolder pt-3">Busca de Hotéis</p>

                <SearchHotel />

                <div className="text-center mb-3">
                    <Button onClick={() => searchHotels(true)} width="200px">
                        <IoSearch/> Refazer Busca
                    </Button>
                </div>

            </Collapse>
        </div>
    )
}
