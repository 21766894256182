import React, {useEffect, useState} from "react";

import {Navigate, useNavigate} from "react-router-dom";
import {Field, FieldArray, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FixedRating} from "components/Rating";
import Button from "components/Button";
import InputMask from 'react-input-mask';

import {cpf} from "cpf-cnpj-validator";

import {CheckIn, Container, Line, LocationMap, Points, Resumo, Title, ValuePoints, Values,} from "./styles";
import format from "date-fns/format";
import {formatCurrency} from "../../../utils/functions";
import {LoaderSearchImage} from "../../../components/Loader";
import {useAuth} from "../../../contexts/AuthProvider";
import {useApp} from "../../../contexts/AppContext";
import {checkoutHotelAction, setTravellersHotelAction} from "../../../contexts/reducers/hotels/actions";
import { isChannel } from "utils";

export default function HotelResume() {
    const [openReserveDetails, setOpenReserveDetails] = useState(false);
    const [loading, setLoading] = useState(false)

    const { hotelState, hotelDispatch } =  useApp()
    const { datesSearchHotel, room, hotel, travellers, offersHotel } = hotelState

    const { customerId } = useAuth()

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    },[])

    const externalProperties = room.externalProperties && JSON.parse(room?.externalProperties)

    const initialValues = {
        travellers: travellers,
    };

    if(!hotel.name) {
        return <Navigate to="/hotels-search-result" />
    }

    if (loading) return <LoaderSearchImage />

    return (
        <Container>
            <p className="resume">Resumo da Reserva</p>

            <div className="row">
                <div className="col-8">
                    <span className="hotel-name">{hotel?.name}</span>
                </div>
                <div className="col-4 star">
                    <FixedRating value={hotel?.stars} size="small"/>
                </div>
            </div>

            <span className="map">
                <LocationMap/>
                {hotel?.address?.street + ', '+ hotel?.address?.city}
            </span>

            <CheckIn>
                <p className="top">Datas</p>
                <p>
                    Check-in
                    <span> {format(datesSearchHotel?.startDate, "dd/MM/yyyy")} </span>
                </p>
                <p>
                    Check-out
                    <span> {format(datesSearchHotel?.endDate, "dd/MM/yyyy")} </span>
                </p>
            </CheckIn>

            {openReserveDetails && (
                <div>
                    <Points>
                        <Title>Diárias</Title>
                        <div className="dots"/>
                        <ValuePoints>{datesSearchHotel?.days} diária(s)</ValuePoints>
                    </Points>
                    <Points>
                        <Title>Quarto</Title>
                        <div className="dots"/>
                        <ValuePoints>{ room?.name}</ValuePoints>
                    </Points>
                    <Points>
                        <Title>Alimentação</Title>
                        <div className="dots"/>
                        <ValuePoints>
                            { room?.board?.description.toUpperCase() === "BREAKFAST"
                                ? "Café da manhã incluso" : "Não incluso" }
                        </ValuePoints>
                    </Points>
                    <Points>
                        <Title>Cancelamento</Title>
                        <div className="dots"/>
                        <ValuePoints>
                            { externalProperties["Refundable"] === "False" || externalProperties["Refundable"] === undefined
                                && "Sem Reembolso"}

                            { externalProperties["Refundable"] === "True"
                                && "Com Reembolso" }
                        </ValuePoints>
                    </Points>
                </div>
            )}

            <Button onClick={() => setOpenReserveDetails(!openReserveDetails)}>
                {openReserveDetails ? "Fechar" : "Ver Detalhes"}
            </Button>

            <Line />

            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    travellers: Yup.array().of(
                        Yup.object().shape({
                            firstName: Yup.string().required().test(function (value) {
                                return ! /[0-9]/.test(value);
                            }),
                            lastName: Yup.string().required().test(function (value) {
                                    return ! /[0-9]/.test(value);
                            }),
                            document: Yup.string().required().test(function (value) {
                                if (value) {
                                    return cpf.isValid(value);
                                } else {
                                    return false
                                }
                            }),
                            birthDate: Yup.string().required().test(function (value) {
                                var arrDate = value.split('/');

                                let dt = new Date(arrDate[1] + '/' + arrDate[0] +'/' + arrDate[2]);
                                var ageDifMs = Date.now() - dt;
                                var ageDate = new Date(ageDifMs);
                                var year = Math.abs(ageDate.getUTCFullYear() - 1970);

                                return year >= 18;
                            }),
                            gender: Yup.string().required(),
                        })
                    )
                })}

                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    hotelDispatch(setTravellersHotelAction(values.travellers))

                    navigate("/checkout/hotel");
                }}
            >
                {({values, setFieldValue,   errors, touched, setFieldTouched}) => (
                    <Form>
                        <FieldArray name="travellers">
                            {() => (
                                <div>
                                    {values.travellers.length > 0 &&
                                        values.travellers.map((traveller, index) =>

                                            <div key={index} className="row gy-3 mb-3">

                                                <span className="label">Dados do Hóspede {index + 1}</span>

                                                <div className="col-12">
                                                    <Field
                                                        name={`travellers.${index}.firstName`}
                                                        placeholder="Primeiro nome"
                                                        className={`form-control form-control-custom border-custom shadow-none
                                                        ${ errors?.travellers !== undefined && errors?.travellers[index] !== undefined
                                                        ?
                                                            touched.travellers && touched.travellers[index] && touched.travellers[index].firstName &&
                                                            errors.travellers[index].firstName && 'is-invalid' : ''}`}

                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <Field
                                                        name={`travellers.${index}.lastName`}
                                                        placeholder="Último sobrenome"
                                                        className={`form-control form-control-custom border-custom shadow-none
                                                        ${ errors?.travellers !== undefined && errors?.travellers[index] !== undefined
                                                        ? touched.travellers && touched.travellers[index] && touched.travellers[index].lastName &&
                                                            errors.travellers[index].lastName && 'is-invalid' : ''}`}
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <InputMask
                                                        name={`travellers.${index}.document`}
                                                        mask="999.999.999-99"
                                                        onClick={() => setFieldTouched(`travellers.${index}.document`, true)}
                                                        onChange={(e) => setFieldValue(`travellers.${index}.document`, e.target.value )}
                                                        className={`form-control form-control-custom border-custom shadow-none
                                                        ${ errors?.travellers !== undefined && errors?.travellers[index] !== undefined
                                                        ? touched.travellers && touched.travellers[index] && touched.travellers[index].document &&
                                                            errors.travellers[index].document && 'is-invalid' : ''}`}
                                                        maskChar={null}
                                                        placeholder="CPF"
                                                        inputMode="numeric"
                                                        defaultValue={values.travellers[index].document}
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <InputMask
                                                        name={`travellers.${index}.birthDate`}
                                                        mask="99/99/9999"
                                                        onClick={() => setFieldTouched(`travellers.${index}.birthDate`, true)}
                                                        onChange={(e) => setFieldValue(`travellers.${index}.birthDate`, e.target.value)}
                                                        className={`form-control form-control-custom border-custom shadow-none
                                                        ${ errors?.travellers !== undefined && errors?.travellers[index] !== undefined
                                                            ? touched.travellers && touched.travellers[index] && touched.travellers[index].birthDate &&
                                                            errors.travellers[index].birthDate  && 'is-invalid' : ''}`}
                                                        maskChar={null}
                                                        placeholder="Data de nascimento"
                                                        inputMode="numeric"
                                                        defaultValue={values.travellers[index].birthDate}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <div className="d-grid gap-3">
                                                        <div className="d-flex">
                                                            <Field
                                                                type="radio"
                                                                name={`travellers.${index}.gender`}
                                                                value="male"
                                                                className={`${ errors?.travellers !== undefined && errors?.travellers[index] !== undefined
                                                                    ? touched.travellers && touched.travellers[index] && touched.travellers[index].gender &&
                                                                    errors.travellers[index].gender && 'border-danger' : ''}`}
                                                            />
                                                            <span
                                                                className="ms-2 text-xs text-muted">Masculino</span>
                                                        </div>

                                                        <div className="d-flex">
                                                            <Field
                                                                type="radio"
                                                                name={`travellers.${index}.gender`}
                                                                value="female"
                                                                className={`${ errors?.travellers !== undefined && errors?.travellers[index] !== undefined
                                                                    ? touched.travellers && touched.travellers[index] && touched.travellers[index].gender &&
                                                                    errors.travellers[index].gender && 'border-danger' : ''}`}
                                                            />
                                                            <span
                                                                className="ms-2 text-xs text-muted">Feminino</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )}
                        </FieldArray>

                        <Resumo>
                            <p className="title-resume">Resumo da Reserva</p>
                            <p className="title">Quarto</p>
                            <p className="value">{ room?.name }</p>

                            <p className="title">Díarias</p>
                            <Points>
                                <Title>{datesSearchHotel?.days} diária(s)</Title>
                                <div className="dots"/>
                                <ValuePoints className="value">
                                    {offersHotel === 'cash' && formatCurrency(room.amount.BRL)}
                                    {offersHotel === 'points' && parseFloat(room.amount.ENVISION).toLocaleString() + ' pontos'}
                                </ValuePoints>
                            </Points>

                            <Points>
                                <Title>Taxas</Title>
                                <div className="dots"/>
                                <ValuePoints className="value">
                                    {offersHotel === 'cash' && 'R$ 0,00'}
                                    {offersHotel === 'points' && 0 + ' pontos'}
                                </ValuePoints>
                            </Points>

                            <Line/>
                            <Values>
                                <Title>Valor total</Title>
                                <div className="dots"/>
                                <ValuePoints>
                                    {offersHotel === 'cash' && formatCurrency(room.amount.BRL * datesSearchHotel?.days)}
                                    {offersHotel === 'points' && parseFloat(room.amount.ENVISION * datesSearchHotel?.days).toLocaleString()+ ' pontos'}
                                </ValuePoints>
                            </Values>
                        </Resumo>

                        <div className="pt-4">
                            <Button type="submit">Continuar</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}
