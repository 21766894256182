import Styled from "styled-components";

export const ButtonQtdBedroom = Styled.div`
width: 100%;
display: flex;
position: relative;
gap: 1em;

.input-group-custom {
    background: transparent;
    border: 0;
    color: #474646;
}

.number-qtd{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #474646;
    flex: 1;
    align-self: flex-start;
    margin-top: 3px;

    .img-cam{
        margin-left: 4px;
        height: 15px;
        width: 15px;
    }

    .img-user{
        margin-left: 4px;
        height: 10px;
        width: 10px;
    }
}

p{
    margin-bottom: 0;
}
`


export const ContainerRoomQuantity = Styled.div`
margin: 2%;

background-color: white;
text-align: center;
`;


export const ButtonQtd = Styled.button`
background: none;
border: none;
display: flex;
padding: 4px;
color: #474646;
`;

export const ContentRoom = Styled.div`
padding: 20px 10px 30px;
margin: 5px 5px 20px;
background-color: #F0F0F0;

.input-group-custom {
    color: #474646;
}
`;

export const ContentQtdRoom = Styled.div `
background-color: #F0F0F0;
padding: 10px 5px;
margin-bottom: 10px;

.form-control-custom {
    background: transparent;
    border: 0;
    font-size: 14px;
    color: #474646;}

.form-control-custom::placeholder {
    color: #474646;
}

.input-group-custom {
    background: transparent;
    border: 0;
    color: #474646;
}

`

export const DivQtd = Styled.div`

background-color: #F0F0F0;

.quarto-1{
color: #263238;
font-size: 17px;
font-weight: 700;
padding-top: 10px;
}
`;

export const Space = Styled.div`
 height: 20px;
 background-color: #F0F0F0;
`
