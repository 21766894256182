import { isChannel } from "utils";
import api from "../../../_config/http";

export function changeLocationsHotelAction(location) {
    return {
        type: 'SEARCH_LOCATION_HOTEL',
        payload: {
            locationSearchHotel: location
        }
    }
}

export function changeDatesHotelAction(dates) {
    const date = dates[0];

    var diffDays = 0

    if(date?.startDate && date?.endDate) {
        var timeDiff = Math.abs(date?.startDate.getTime() - date?.endDate.getTime());
        diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    const datesFormat = {
        startDate: date?.startDate,
        endDate: date?.endDate,
        days: diffDays
    }

    return {
        type: 'SEARCH_DATES_HOTEL',
        payload: {
            datesSearchHotel: datesFormat
        }
    }
}

export function changeGuestsAction(guests) {
    return {
        type: 'SEARCH_GUESTS_HOTEL',
        payload: {
            guestsSearchHotel: guests
        }
    }
}

export const changeOfferHotelAction = (data) => {
    return {
        type: 'SEARCH_OFFER_HOTEL',
        payload: {
            offersHotel: data
        }
    }
}

export const changeSelectedHotelAction = (data) => {
    return {
        type: 'SEARCH_SELECTED_HOTEL',
        payload: {
            hotel: data
        }
    }
}

export const changeSelectedRoomHotelAction = (data) => {
    return {
        type: 'SEARCH_SELECTED_ROOM_HOTEL',
        payload: {
            room: data
        }
    }
}

export const setTravellersHotelAction = (data) => {
    return {
        type: 'SET_TRAVELLERS_HOTEL',
        payload: {
            travellers: data
        }
    }
}

export const setHotelsListAction = (data) => {
    return {
        type: 'SET_HOTELS_LIST',
        payload: {
            hotelsList: data
        }
    }
}

export const clearSearch = () => {
    return {
        type: 'CLEAR_SEARCH'
    }
}

export const checkoutHotelAction = (props) => {
    const {customerId, hotel, room, cardSelected, totalPayment, installmentSelected, travellers, offersHotel } = props
    const customer = localStorage.getItem("customer_envision_v1")

    const formatTravellers = travellers.map(item => {

        const [day, month, year] = item.birthDate.split('/');
        const birthDate = year + '-' + month + '-' + day;

        let document = item?.document?.replaceAll('.', '').replace('-', '')

        return {
            "firstName": item.firstName,
                "middleName": "",
                "lastName": item.lastName,
                "birthDate": birthDate,
                "gender": item.gender,
                "documents": [
                {
                    "number": document,
                    "documenType": "cpf"
                }
            ]
        }
    })

    hotel['rooms'] = [room]

    const checkout = {
        "customerId": localStorage.getItem('customer_parent'),
        "contactInformations": JSON.parse(customer),
        "payment_type": offersHotel,
        "fromChannel": isChannel('genial') ? 'genial' : 'll',
        "travellers": formatTravellers,
        "hotel": {
            "hotels": [
                hotel
            ]
        },
    }

    const data = JSON.stringify(checkout)
    
    if (! isChannel('genial')) {
        checkout.payment = [
            {
                "type": "points",
                "amount": totalPayment.points
            },
            {
                "type": "CREDIT",
                "gateway_card_key": cardSelected.gateway_card_key,
                "amount": totalPayment.cash,
                "installments": installmentSelected,
                "save": false
            }
        ];

        const data = JSON.stringify(checkout)

        return api.post('/hotels/order', data)
    } else {
        localStorage.setItem('customer_parent', '')
        return api.post('/hotels/pendent_order', data)
    }
}