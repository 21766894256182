import React, {useEffect, useState} from 'react'

import { Container } from 'react-bootstrap'

import ReactPaginate from "react-paginate";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai"; // icons form react-icons
import { IconContext } from "react-icons"; // for customizing icons

import Map from 'components/Map'
import FilterHotels from 'components/FilterResult'
import SearchBarHotels from 'components/SearchBarHotels'
import { NotFoundResultsHotel } from "components/NotFoundResults";
import { BoxHotel } from "components/BoxHotel";
import Pagination from 'components/Pagination'
import { LoaderSearchImage } from "components/Loader";

import {initial_filter_hotels, isChannel} from "utils";

import { getHotels } from "_services/hotels.service";
import { useApp } from "contexts/AppContext";
import { setHotelsListAction } from "../../contexts/reducers/hotels/actions";

import "./paginationStyle.css"
import {useGenialApp} from "../../contexts/GenialAppContext";


export default function HotelsSearchResult() {
    const [loading, setLoading] = useState(false)
    const [openMap, setOpenMap] = useState(false)
    const [info, setInfo] = useState(true)
    const [data, setHotels] = useState([])
    const [totalItems, seTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState(initial_filter_hotels)
    const [order, setOrder] = useState('')

    const { hotelState, hotelDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { datesSearchHotel, locationSearchHotel, guestsSearchHotel } = hotelState

    const [page, setPage] = useState(0);
    const [filterData, setFilterData] = useState();
    const n = 10
    

    async function searchHotels(firstPage = false) {
        if (locationSearchHotel?.id) {
            setLoading(true)
            
            const response = await getHotels(
                page,
                guestsSearchHotel,
                datesSearchHotel,
                locationSearchHotel,
                filter,
                order
            );

            if (!response.status) {
                setHotels([])
                setInfo(true)
                setLoading(false)
                setFilterData([])
                return
            }

            const dataHoteisFilter = []

            if ( typeof response.data != "undefined") {
                
                setFilterData(
                    response.data.hotels.filter((item, index) => {
                        return (index >= page * n) & (index < (page + 1) * n);
                    })
                );
            }

            if (response.status) {
                setHotels(response.data.hotels)
                hotelDispatch(setHotelsListAction(response.data.hotels))
                seTotalItems(response.data.totalFound)
            }

            setLoading(false)
            setInfo(true)
        }
    }

    useEffect(() => {
        const Request = async () => {
            searchHotels()
            
        }
        Request();
    }, [page, order, filter ])

    
    const applyFilter = (filters) => {
        setFilter(filters)
    }

    const handleApplyOrder = (orderFilter) => {
        setOrder(orderFilter)
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
    }

    const handleInfo = () => {
        setInfo(!info)
    }

    function changePage(pageNumber) {
        setFilterData(
            data.filter((item, index) => {
                return (index >= pageNumber * n) & (index < (pageNumber + 1) * n);
            })
        );

        window.scrollTo(0, 0)
    }


    if (loading) return <LoaderSearchImage />

    return (
        <>
            <div className="position-fixed w-100 bg-white">
                <SearchBarHotels
                    info={info}
                    handleInfo={handleInfo}
                    searchHotels={searchHotels}
                />
                <Container>
                    <div className="filter-content">
                        <FilterHotels
                            filter={filter}
                            applyFilter={applyFilter}
                            handleApplyOrder={handleApplyOrder}
                            order={order}
                            showMap={() => setOpenMap(!openMap)}
                            openMap={openMap}
                        />
                    </div>
                </Container>
            </div>

            <Container className="mt-5">
                <div className="result-content" style={{paddingTop: "300px"}}>
                    { !filterData?.length &&
                        <NotFoundResultsHotel handleInfo={handleInfo} />
                    }

                    { filterData?.length > 0 &&
                        filterData.map((hotel, index) =>
                            <div key={index}>
                                <BoxHotel data={hotel} id={index} />
                            </div>
                    )}

                    {/*{ data.length > 0 && openMap*/}
                    {/*    ? <Map />*/}
                    {/*    : data.map((hotel, index) =>*/}
                    {/*        <div key={index}>*/}
                    {/*            <BoxHotel data={hotel} id={index} />*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*}*/}
                </div>

                { filterData?.length > 0 && !openMap &&
                    <ReactPaginate
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        activeClassName={"active"}
                        onPageChange={(event) => changePage(event.selected)}
                        pageCount={Math.ceil(totalItems / n)}
                        breakLabel="..."
                        previousLabel={
                            "< Anterior"
                        }
                        nextLabel={
                            "Próximo >"
                        }
                    />
                }
                
            </Container>
        </>
    )

}
